import React from 'react';
import './App.css';
import Home from './pages/home/Home';
import ArticleMenuPage from './pages/articlesMenu/ArticleMenuPage';
import ArticlePage from './pages/articles/ArticlePage';
import ChimeInPage from './pages/chimeIn/ChimeIn';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' exact element={ <Home />} />
          <Route path="/articles" element={<ArticleMenuPage />} />
          <Route path="/articles/:id" element={<ArticlePage />} />
          <Route path="chime" element={<ChimeInPage />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import phoneImage2 from '../../Assets/PhoneImage2.png';
import codeBackground from '../../Assets/OnlineForum.png';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import './ChimeIn.css'; // Default CSS for desktop
import './ChimeInMobile.css'; // Mobile-specific CSS

const ChimeInPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showInfo, setShowInfo] = useState(false); // New state for "About the App"

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set mobile view if screen width is <= 768px
    };

    handleResize(); // Check on initial load
    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup event listener on unmount
    };
  }, []);

  const [showForm, setShowForm] = useState(false);
  const [formType, setFormType] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleButtonClick = (type) => {
    if (type === 'About the App') {
      setShowInfo(true); // Show "About the App" information
      setShowForm(false); // Hide form if it was showing
    } else {
      setFormType(type);
      setShowForm(true);
      setShowInfo(false); // Hide "About the App" information
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      subject: formType,
      email,
      message,
    };

    emailjs
      .send(
        'service_3attc3x', 
        'template_q14vnef',
        templateParams,
        '34_NAHieVdhKxr9po'
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Message Sent! Thank you!');
          setEmail('');
          setMessage('');
          setShowForm(false);
        },
        (err) => {
          console.log('FAILED...', err);
          alert('Failed to send email. Please try again later.');
        }
      );
  };

  return (
    <div className={`relative min-h-screen bg-blue-500 flex flex-col items-center justify-start ${isMobile ? 'mobile' : ''}`}>
      <div
        className="absolute inset-0 bg-cover bg-center opacity-50"
        style={{ backgroundImage: `url(${codeBackground})` }}
      ></div>
      <div className="relative z-10 w-full p-8 flex justify-between items-start">
        <h1 className="text-white text-3xl font-bold">Chime.In</h1>
        <Link to="/">
          <button className="store-button">Home</button>
        </Link>
      </div>

      <div className={`relative z-10 w-full mt-8 flex ${isMobile ? 'mobile-layout' : 'desktop-layout'}`}>
        <div className="text-white w-full">
          <h2 className="text-5xl font-bold mb-4 text-center">
            Bringing the internet back to users one forum at a time.
          </h2>
          
          {/* Centered Buttons */}
          <div className="flex justify-center space-x-6 mt-6">
            <button className="store-button" onClick={() => handleButtonClick('About the App')}>
              About the App
            </button>
            <div style={{width: 20}} />
            <button className="store-button" onClick={() => handleButtonClick('Join the App Waitlist')}>
              Join the app waitlist
            </button>
          </div>

          <div style={{height: 20}} />

          {/* Show about info */}
          {showInfo && (
            <div className="about-info bg-opacity-80 bg-white p-6 mt-6 rounded-md shadow-md">
              <p className="text-gray-800 text-lg">
                Chime.In aims to create a central platform for individual forums, providing a space
                for users to explore and personalize their interests. 
              </p>
              <p style={{marginTop: 10}}>
                Our app seeks to revitalize the concept of dedicated forums, allowing
                you to engage with content that matters most to you.               
              </p>
              <p style={{marginTop: 10}}>
                Stay tuned for new features, and join our waitlist to be the first to know when Chime.In goes live!
              </p>
            </div>
          )}

          {/* Show form if needed */}
          {showForm && (
            <div className="relative z-10 w-full max-w-lg mt-8 bg-white p-8 rounded-md shadow-md">
              <h3 className="text-blue-500 text-2xl font-bold mb-4">{formType}</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Subject</label>
                  <input
                    style={{ padding: 5 }}
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    value={formType}
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Email</label>
                  <input
                    style={{ padding: 5 }}
                    type="email"
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Message</label>
                  <textarea
                    style={{ padding: 5 }}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter your message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </div>
                <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-md">
                  Submit
                </button>
              </form>
            </div>
          )}
        </div>
        <div className="w-full flex justify-end">
          <img src={phoneImage2} alt="Phone app" className="w-full" />
        </div>
      </div>
    </div>
  );
};

export default ChimeInPage;
